import {
    SET_USERS,
    CLEAR_USERS,
    SET_USER,
    CLEAR_USER,
    IS_LOADING,
    SET_PAGE,
    SET_OFFSET,
    CLEAR_PAGE
} from '../actions/users.action';

const initialState = {
    isLoading: false,
    users: [],
    user: {},
    queryFields: 'email,name,voivodeship',
    total: 0,
    page: 1,
    limit: 20,
    offset: 0,
    voivodeships: [
        { value: 'dolnośląskie', label: 'dolnośląskie' },
        { value: 'kujawsko-pomorskie', label: 'kujawsko-pomorskie' },
        { value: 'lubelskie', label: 'lubelskie' },
        { value: 'lubuskie', label: 'lubuskie' },
        { value: 'łódzkie', label: 'łódzkie' },
        { value: 'małopolskie', label: 'małopolskie' },
        { value: 'mazowieckie', label: 'mazowieckie' },
        { value: 'opolskie', label: 'opolskie' },
        { value: 'podkarpackie', label: 'podkarpackie' },
        { value: 'podlaskie', label: 'podlaskie' },
        { value: 'pomorskie', label: 'pomorskie' },
        { value: 'śląskie', label: 'śląskie' },
        { value: 'świętokrzyskie', label: 'świętokrzyskie' },
        { value: 'warmińsko-mazurskie', label: 'warmińsko-mazurskie' },
        { value: 'wielkopolskie', label: 'wielkopolskie' },
        { value: 'zachodniopomorskie', label: 'zachodniopomorskie' }
    ],
    sortOptions: [
        { value: { 'sort[birthDate]': -1 }, label: 'Data urodzin malejąco' },
        { value: { 'sort[birthDate]': 1 }, label: 'Data urodzin rosnąco' },
        { value: { 'sort[createdAt]': -1 }, label: 'Data rejestracji malejąco', default: true },
        { value: { 'sort[createdAt]': 1 }, label: 'Data rejestracji rosnąco' },
        { value: { 'sort[name]': -1 }, label: 'Imię i nazwisko malejąco' },
        { value: { 'sort[name]': 1 }, label: 'Imię i nazwisko rosnąco' },
        { value: { 'sort[voivodeship]': -1 }, label: 'Województwo malejąco' },
        { value: { 'sort[voivodeship]': 1 }, label: 'Województwo rosnąco' }
    ]
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case SET_USERS:
            return {
                ...state,
                users: action.users,
                total: action.total
            };
        case CLEAR_USERS:
            return {
                ...initialState
            };
        case SET_USER:
            return {
                ...state,
                user: action.user
            };
        case CLEAR_USER:
            return {
                ...state,
                user: {}
            };
        case SET_PAGE:
            return {
                ...state,
                page: action.page
            };
        case SET_OFFSET:
            return {
                ...state,
                offset: action.offset
            };
        case CLEAR_PAGE:
            return {
                ...state,
                page: 1
            };
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        default:
            return state;
    }
}