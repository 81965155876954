import React from 'react';
import { connect } from 'react-redux'
import UserComponent from '../components/user.component';

import { getUser, clearUser, saveUser, deleteUser } from '../actions/users.action';

function UserContainer(props) {
    return (
        <UserComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        user: state.UserReducer.user,
        voivodeships: state.UserReducer.voivodeships,
        isLoading: state.UserReducer.isLoading
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUser: (id) => dispatch(getUser(id)),
        clearUser: () => dispatch(clearUser()),
        saveUser: (data) => saveUser(data, dispatch),
        deleteUser: (id) => deleteUser(id, dispatch)
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);
