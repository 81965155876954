import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Link, useLocation, useHistory } from "react-router-dom";
import { logout } from '../actions/app.action';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt, faSearch, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

import '../assets/css/admin-header.css';

function AdminHeaderComponent(props) {

    const { logout, setQuery } = props;
    const location = useLocation();
    const history = useHistory();

    const [addButtonRoute, setAddButtonRoute] = useState('');
    const [headerName, setHeaderName] = useState('');
    const [searchBar, showSearchBar] = useState(true);
    const [route, setRoute] = useState('');

    function handleLogout() {
        logout();
        history.push('/');
    }

    useEffect(() => {

        switch (true) {
            case /^[/]notification[/]/i.test(location.pathname):
                showSearchBar(false);
                break;
            case /^[/]user[/]/i.test(location.pathname):
                showSearchBar(false);
                break;
            case /^[/]diaries/i.test(location.pathname):
                showSearchBar(false);
                break;
            default:
                showSearchBar(true);
                break;
        }

        switch (location.pathname) {
            case '/notifications':
                setHeaderName('Powiadomienia');
                setAddButtonRoute('/notification');
                setRoute('notifications');
                break;
            case '/diaries':
                setHeaderName('Dzienniki');
                setAddButtonRoute('');
                break;
            case '/users':
                setHeaderName('Uzytkownicy');
                setAddButtonRoute('/user');
                setRoute('users');
                break;
            default:
                setAddButtonRoute('');
                break;
        }

    }, [location]);

    return <div className="d-flex admin-header-wrapper">
        <div className="d-flex pl-4 pr-5 justify-content-start align-items-center">
            <h5>{headerName}</h5>
        </div>
        {searchBar && <div className="parameters-list-wrapper d-flex flex-fill flex-row">
            <div className="list-search-wrapper d-flex flex-fill justify-content-center align-items-center">
                <FontAwesomeIcon icon={faSearch} className="search-icon" color="#44503f" />
                <input type="text" name="abcd" onChange={(e) => setQuery(e.target.value, route)} className="form-control" id="login" />
            </div>
        </div>}
        <div className="d-flex flex-fill pr-4 justify-content-end align-items-center">
            <Link className="d-flex" to={addButtonRoute}>
                {addButtonRoute !== '' && <FontAwesomeIcon icon={faPlusSquare} color="#68bb73" className="add-icon" />}
            </Link>
            <FontAwesomeIcon onClick={() => handleLogout()} icon={faSignInAlt} color="#252525" className="logout-icon" />
        </div>
    </div>
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout())
    }
};

export default connect(null, mapDispatchToProps)(AdminHeaderComponent);