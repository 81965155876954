import React from 'react';
import { Link } from "react-router-dom";
import moment from 'moment';

import '../assets/css/notificationCard.css';
moment.locale("pl");

function NotificationListCardComponent({ index, record }) {
  return <div className={`d-flex list-card-wrapper ${!record.active ? "no-active" : ""} flex-row`}>
    <Link className="d-flex flex-fill" to={`/notification/${record._id}`}>
      <div className="card-section card-label-wrapper justify-content-center w-50 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Tytuł</div>
        <div className="card-label-value">{record.title.slice(0, 25)} {record.title.length > 25 ? '...' : ''}</div>
      </div>
      <div className="card-section card-label-wrapper  justify-content-center w-50 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Opis</div>
        <div className="card-label-value">{record.description.slice(0, 25)} {record.description.length > 25 ? '...' : ''}</div>
      </div>
      <div className="card-section card-label-wrapper  justify-content-center w-50 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Data dodania</div>
        <div className="card-label-value">{moment(record.createdAt).format('DD-MM-YYYY, h:mm:ss')}</div>
      </div>
      <div className="card-section card-label-wrapper  justify-content-center w-50 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Cykliczne</div>
        <div className="card-label-value">{record.repeat ? 'Tak' : 'Nie'}</div>
      </div>
      <div className="card-section card-label-wrapper  justify-content-center w-50 align-items-start d-flex flex-fill flex-column">
        <div className="card-label">Użytkownicy</div>
        {record.allUsers
          ?
          <div className="card-label-value">Wszyscy</div>
          : record.usernames && record.usernames.map(user => {
            if (user && user.name) {
              return (
                <div className="card-label-value">{user.name}</div>
              )
            }
          })
        }
      </div>
    </Link>
  </div>
}

export default NotificationListCardComponent;