import React from "react";
import { connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Redirect
} from "react-router-dom";

import { checkIsAuth, setQuery } from '../actions/app.action';
import AppLoader from '../components/loader.component';

import LoginContainer from '../containers/login.container';
import UsersContainer from '../../users/containers/users.container';
import DiariesContainer from '../../diaries/containers/diaries.container';
import NotificationsContainer from '../../notifications/containers/notifications.container';

import UserContainer from '../../users/containers/user.container';
import NotificationContainer from '../../notifications/containers/notification.container';

import AdminSidebarComponent from "../components/adminSidebar.component";
import AdminHeaderComponent from "../components/adminHeader.component";

import ToastComponent from "../components/toast.component";

function App(props) {

  const { appIsLoading, isAuth, setQuery } = props;

  return (
    <div className="d-flex">
      {appIsLoading && <AppLoader type="absolute" />}
      <Router>
        {isAuth && <div className="d-flex">
          <AdminSidebarComponent />
        </div>}
        <div className="d-flex flex-fill flex-column">
          {isAuth && <div className="d-flex">
            <AdminHeaderComponent setQuery={setQuery} />
          </div>}
          <div className={`d-flex flex-fill admin-right-wrapper ${!isAuth ? "no-auth" : ""}`}>
            <Route exact path={["/", "/login"]} >
              <LoginContainer />
            </Route>
            <PrivateRoute {...props} exact path={["/users/page/:page?", "/users"]}>
              <UsersContainer />
            </PrivateRoute>
            <PrivateRoute {...props} path={["/user/:id", "/user"]}>
              <UserContainer />
            </PrivateRoute>
            <PrivateRoute {...props} path="/diaries">
              <DiariesContainer />
            </PrivateRoute>
            <PrivateRoute {...props} exact path={["/notifications/page/:page?", "/notifications"]}>
              <NotificationsContainer />
            </PrivateRoute>
            <PrivateRoute {...props} path={["/notification/:id", "/notification"]}>
              <NotificationContainer />
            </PrivateRoute>
          </div>
          <ToastComponent />
        </div>
      </Router>
    </div>
  );

  function PrivateRoute({ children, appIsLoading, isAuth, loggedUser, ...rest }) {
    let isAllowed = appIsLoading || (isAuth && loggedUser && loggedUser.isAdmin);
    return (
      <Route
        {...rest}
        render={({ location }) =>
          isAllowed ? (
            children
          ) : (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location }
                }}
              />
            )
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  appIsLoading: state.AppReducer.appIsLoading,
  isAuth: state.AppReducer.isAuth,
  loggedUser: state.AppReducer.loggedUser
});

const mapDispatchToProps = (dispatch) => {
  return {
    checkIsAuth: () => dispatch(checkIsAuth()),
    setQuery: (value, route) => dispatch(setQuery(value, route))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
