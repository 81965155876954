import Api from '../services/api.service';
import FileSaver from 'file-saver';
import { getUsers } from '../../users/actions/users.action';
import { getNotifications } from '../../notifications/actions/notifications.action';

const namespace = 'APP';

export const SET_LOGGED_USER = `SET_LOGGED_USER_${namespace}`;
export const APP_IS_LOADING = `APP_IS_LOADING_${namespace}`;
export const LOGOUT_USER = `LOGOUT_USER_${namespace}`;
export const SET_QUERY = `SET_QUERY_${namespace}`;
export const CLEAR_QUERY = `CLEAR_QUERY_${namespace}`;
export const SHOW_TOAST = `SHOW_TOAST_${namespace}`;
export const CLEAR_TOAST = `CLEAR_TOAST_${namespace}`;
export const SET_SORT_BY = `SET_SORT_BY_${namespace}`;
export const CLEAR_SORT_BY = `CLEAR_SORT_BY_${namespace}`;

export const showToast = (toastType, message) => {
  return function (dispatch) {

    dispatch({
      type: SHOW_TOAST,
      toastType,
      message
    });

    setTimeout(() => {
      dispatch(clearToast());
    }, 3000);

  }
};

export const setSortBy = (sortyByOption, route) => {
  return dispatch => {

    dispatch({
      type: SET_SORT_BY,
      sortyByOption
    });

    switch (route) {
      case '/users':
        dispatch(getUsers());
        break;
      case '/notifications':
        dispatch(getNotifications());
        break;
      default:
        break;
    };
  }
}

export const claerSortBy = () => ({
  type: CLEAR_SORT_BY
});

export const clearToast = () => ({
  type: CLEAR_TOAST
});

// ustawiamy zalogowanego użytkownikaF
export const setIsLogged = (loggedUser) => ({
  type: SET_LOGGED_USER,
  loggedUser
});

// wylogowujemy się, czyścimy token
export const logout = () => {
  return dispatch => {
    dispatch({
      type: LOGOUT_USER
    });
    localStorage.removeItem('token');
  }
}

// isLoading aplikacji
export const isLoading = (appIsLoading) => {
  return dispatch => {
    dispatch({
      type: APP_IS_LOADING,
      appIsLoading
    });
  }
}

// ustawiamy wartość filtrowaną i pobieramy listę
export const setQuery = (query, route) => {
  return dispatch => {
    dispatch({
      type: SET_QUERY,
      query
    });
    switch (route) {
      case 'users':
        dispatch(getUsers());
        break;
      case 'notifications':
        dispatch(getNotifications());
        break;
      default:
        break;
    }
  }
}

export const clearQuery = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_QUERY
    });
  }
}

// metoda do logowania
export function loginIn(loginData, dispatch) {
  return new Promise((resolve, reject) => {

    dispatch(isLoading(true));
    // timeout bo za szybko xd
    return Api.post('admin/login', loginData).then((result) => {
      dispatch(isLoading(false));

      if (result.success) {
        dispatch(setIsLogged(result.user));
        localStorage.setItem('token', result.token);

        return resolve();
      } else if ('errors' in result) {
        return reject(result.errors);
      }
    });
  });
}

export function checkIsAuth() {
  return function (dispatch) {

    dispatch(isLoading(true));

    Api.get('admin/isAuth').then((result) => {

      if (result.success) {
        dispatch(setIsLogged(result.user));
        localStorage.setItem('token', result.token);
      } else {
        dispatch(logout());
      }
      dispatch(isLoading(false));
    });
  }
}

export function getReport() {
  return (dispatch) => {
    dispatch(isLoading(true));
    return Api.get(`admin/getReport`, null, true).then((result) => {

      dispatch(isLoading(false));

      if (result && !result.err) {
        FileSaver.saveAs(result, "Raport.xlsx");
      } else {
        dispatch(showToast('alert', 'Błąd podczas pobierania raportu'));
      }

    });
  };
}