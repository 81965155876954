import React, { useState, useEffect, Fragment } from 'react';
import { useLocation } from "react-router-dom";
import Select from 'react-select';
import moment from 'moment';
import DatePicker from 'react-date-picker';
import AppLoader from '../../application/components/loader.component';
import '../assets/css/diaries.css';

import Mood1 from '../assets/icons/mood1.svg';
import Mood2 from '../assets/icons/mood2.svg';
import Mood3 from '../assets/icons/mood3.svg';
import Mood4 from '../assets/icons/mood4.svg';
import Mood5 from '../assets/icons/mood5.svg';

function DiariesComponent(props) {

  const { isLoading, getUsers, clearStore, users, diaries, setSelectedUser, setSelectedDay, selectedUser } = props;
  const [value, onChange] = useState(new Date());

  let locationState = useLocation().state;

  useEffect(() => {
    setSelectedDay(value);
    if (locationState && locationState.userId) {
      getUsers(locationState.userId);
    } else {
      getUsers();
    }
  }, [getUsers, clearStore, value, setSelectedDay, locationState]);

  useEffect(() => {

  }, [])

  useEffect(() => {
    return () => {
      clearStore();
    }
  }, [clearStore])

  function selectDayHendler(value) {
    onChange(value);
    setSelectedDay(value);
  }

  const DiaryMood = (type) => {
    switch (type) {
      case 1:
        return Mood1;
      case 2:
        return Mood2;
      case 3:
        return Mood3;
      case 4:
        return Mood4;
      case 5:
        return Mood5;
      default: return;
    }
  }

  function getDiaresView() {
    return <div className="diary-elements-wrapper d-flex flex-fill flex-column">
      {diaries.map((diary, index) => {

        let colorClass = '';
        let labelName = '';
        let content = '';
        switch (diary.type) {
          case 'feeling':
            colorClass = 'green-diary';
            labelName = 'Co czuję?';
            content = <div key={`diary-details-${index}`} className={`diary-element ${colorClass} d-flex flex-column flex-fill justify-content-center`}>
              <div className="d-flex feeling-element flex-column">
                <label>Co czuję?</label>
                <p>{diary.document.name}</p>
              </div>
              <div className="d-flex flex-fill flex-column">
                <label>Co robie?</label>
                <p>{diary.document.pleasure}</p>
              </div>
            </div>;
            break;
          case 'meal':
            colorClass = 'red-diary';
            labelName = 'Co zjadłem?';
            content = <div key={`diary-details-${index}`} className={`diary-element ${colorClass} d-flex flex-row flex-fill justify-content-flex-start`}>
              <div className="d-flex flex-column">
                <div className="d-flex flex-fill flex-column">
                  <label>Co zjadłem?</label>
                  <p>{diary.document.name}</p>
                </div>
                <div className="d-flex">
                  <div className="d-flex feeling-element flex-column">
                    <label>Poziom nasycenia</label>
                    <p><img width="25px" height="25px" alt="fullfilment" src={DiaryMood(diary.document.fullfilment)} /></p>
                  </div>
                  <div className="d-flex flex-fill flex-column">
                    <label>Jak się czuję po posiłku</label>
                    <p><img width="25px" height="25px" alt="mood" src={DiaryMood(diary.document.mood)} /></p>
                  </div>
                </div>
              </div>
            </div>;
            break;
          case 'exercise':
            colorClass = 'orange-diary';
            labelName = 'Jaki ruch wykonałem?';
            content = <div key={`diary-details-${index}`} className={`diary-element ${colorClass} d-flex flex-column flex-fill justify-content-center`}>
              <label>{labelName}</label>
              <p>{diary.document.name}</p>
            </div>
            break;
          case 'drink':
            colorClass = 'blue-diary';
            labelName = 'Co piłem?';
            content = <div key={`diary-details-${index}`} className={`diary-element ${colorClass} d-flex flex-column flex-fill justify-content-center`}>
              <label>{labelName}</label>
              <p>{diary.document.name}</p>
            </div>
            break;
          default:
            colorClass = 'black'
            break;;
        }

        return <div key={`diary-element-${index}`} className="diary-element-wrapper d-flex flex-row">
          <div className="diary-element-date d-flex flex-column justify-content-center">
            <span>Godzina</span>
            <span>{moment(diary.date).format("HH:mm:ss")}</span>
          </div>
          {content}
        </div>
      })}
      {diaries.length === 0 && <div className="no-element-wrapper d-flex flex-fill justify-content-center align-items-center">
        <span>brak elementów</span>
      </div>}
    </div>
  }

  return <Fragment>
    <div className="d-flex diaries-wrapper flex-fill flex-column">
      <div className="d-flex diaries-search-wrapper">
        <div className="diary-user">
          <label>Wybierz użytkownika</label>
          <Select
            placeholder="Wybierz"
            name="user"
            options={users}
            className="basic-multi-select"
            classNamePrefix="select"
            value={selectedUser}
            onChange={(value) => setSelectedUser(value)}
          />
        </div>
        <div className="diary-date d-flex flex-column">
          <label>Wybierz dzień</label>
          <div className="diary-date-picker-wrapper">
            <DatePicker
              onChange={selectDayHendler}
              value={value}
            />
          </div>
        </div>
      </div>
      {isLoading ? <AppLoader /> : getDiaresView()}
    </div>
  </Fragment>

}

export default DiariesComponent;