import React from 'react';
import { connect } from 'react-redux'
import UsersComponent from '../components/users.component';

import { getUsers, clearUsers, setPaginationRoute, clearPaginationRoute } from '../actions/users.action';
import { clearQuery } from '../../application/actions/app.action';

function UsersContainer(props) {
    return (
        <UsersComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.UserReducer.isLoading,
        users: state.UserReducer.users,
        total: state.UserReducer.total,
        limit: state.UserReducer.limit,
        paginationRoute: state.UserReducer.page,
        sortOptions: state.UserReducer.sortOptions
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: () => dispatch(getUsers()),
        clearUsers: () => dispatch(clearUsers()),
        setPaginationRoute: (page) => dispatch(setPaginationRoute(page)),
        clearPaginationRoute: () => dispatch(clearPaginationRoute()),
        clearQuery: () => dispatch(clearQuery())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);
