import React from 'react';
import { connect } from 'react-redux'
import DiariesComponent from '../components/diaries.component';

import { getDiaries, getUsers, setSelectedUser, setSelectedDay, clearStore } from '../actions/diaries.action';

function DiariesContainer(props) {
  return (
    <DiariesComponent {...props} />
  );
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.DiaryReducer.isLoading,
    diaries: state.DiaryReducer.diaries,
    selectedUser: state.DiaryReducer.selectedUser,
    users: state.DiaryReducer.users
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    getDiaries: () => dispatch(getDiaries()),
    getUsers: (selectedUser) => dispatch(getUsers(selectedUser)),
    setSelectedUser: (selectedUser) => dispatch(setSelectedUser(selectedUser)),
    setSelectedDay: (selectedDay) => dispatch(setSelectedDay(selectedDay)),
    clearStore: () => dispatch(clearStore())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(DiariesContainer);
