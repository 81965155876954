import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import List from '../../application/components/list.component';
import UserListCard from './userListCard.component';

function UsersComponent(props) {

  const { isLoading, users, getUsers, clearUsers, total, limit, paginationRoute, setPaginationRoute, clearPaginationRoute, clearQuery, sortOptions } = props;

  let { page } = useParams();

  useEffect(() => {
    getUsers();
    return () => {
      clearPaginationRoute();
      clearQuery();
      clearUsers();
    }
  }, [clearPaginationRoute, clearQuery, getUsers, clearUsers]);

  useEffect(() => {
    if (page) {
      setPaginationRoute(page || 1);
    }
  }, []);

  return <div className="d-flex flex-fill">
    <List data={users} Card={UserListCard} isLoading={isLoading} total={total} paginationRoute={parseInt(paginationRoute)} limit={limit} setPaginationRoute={setPaginationRoute} route="/users" sortOptions={sortOptions} />
  </div>
}

export default UsersComponent;