import {
    SET_NOTIFICATIONS,
    SET_NOTIFICATION,
    CLEAR_NOTIFICATIONS,
    CLEAR_NOTIFICATION,
    SET_USERS,
    SET_SELECTED_USER,
    CLEAR_USERS,
    IS_LOADING,
    SET_PAGE,
    SET_OFFSET,
    CLEAR_PAGE
} from '../actions/notifications.action';

const initialState = {
    isLoading: false,
    users: [],
    selectedUser: null,
    notifications: [],
    notification: {},
    queryFields: 'title,description',
    total: 0,
    page: 1,
    limit: 20,
    offset: 0,
    sortOptions: [
        { value: { 'sort[createdAt]': -1 }, label: 'Data utworzenia malejąco', default: true },
        { value: { 'sort[createdAt]': 1 }, label: 'Data utworzenia rosnąco' },
        { value: { 'sort[title]': -1 }, label: 'Tytuł malejąco' },
        { value: { 'sort[title]': 1 }, label: 'Tytuł rosnąco' }
    ]
};

export default function NotificationReducer(state = initialState, action) {
    switch (action.type) {
        case SET_USERS:
            return {
                ...state,
                users: action.users
            };
        case SET_SELECTED_USER:
            return {
                ...state,
                selectedUser: action.selectedUser
            };
        case CLEAR_USERS:
            return {
                ...initialState
            };
        case SET_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.notifications,
                total: action.total
            };
        case SET_NOTIFICATION:
            return {
                ...state,
                notification: action.notification,
            };
        case CLEAR_NOTIFICATIONS:
            return {
                ...state,
                notifications: []
            };
        case CLEAR_NOTIFICATION:
            return {
                ...state,
                notification: {}
            };
        case SET_PAGE:
            return {
                ...state,
                page: action.page
            };
        case SET_OFFSET:
            return {
                ...state,
                offset: action.offset
            };
        case CLEAR_PAGE:
            return { ...state, page: 1 };

        case IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        default:
            return state;
    }
}