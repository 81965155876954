import {
    SET_DIARIES,
    SET_USERS,
    SET_SELECTED_USER,
    SET_SELECTED_DAY,
    CLEAR_STORE,
    IS_LOADING,
} from '../actions/diaries.action';

const initialState = {
    isLoading: false,
    diaries: [],
    users: [],
    selectedUser: {},
    selectedDay: new Date()
};

export default function DiaryReducer(state = initialState, action) {
    switch (action.type) {
        case SET_DIARIES:
            return {
                ...state,
                diaries: action.diaries
            };
        case SET_USERS:
            return {
                ...state,
                users: action.users
            };
        case SET_SELECTED_USER:
            return {
                ...state,
                selectedUser: action.selectedUser
            };
        case SET_SELECTED_DAY:
            return {
                ...state,
                selectedDay: action.selectedDay
            };
        case IS_LOADING:
            return {
                ...state,
                isLoading: action.isLoading
            };
        case CLEAR_STORE:
            return {
                ...initialState
            };
        default:
            return state;
    }
}