import React from 'react';
import { connect } from 'react-redux'
import NotificationsComponent from '../components/notifications.component';

import { getNotifications, clearNotifications, setPaginationRoute, clearPaginationRoute, getUsers,setSelectedUser } from '../actions/notifications.action';
import { clearQuery } from '../../application/actions/app.action';

function NotificationsContainer(props) {
    return (
        <NotificationsComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.NotificationReducer.isLoading,
        notifications: state.NotificationReducer.notifications,
        total: state.NotificationReducer.total,
        limit: state.NotificationReducer.limit,
        paginationRoute: state.NotificationReducer.page,
        sortOptions: state.NotificationReducer.sortOptions,
        users: state.NotificationReducer.users,
        selectedUser: state.NotificationReducer.selectedUser
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNotifications: () => dispatch(getNotifications()),
        clearNotifications: () => dispatch(clearNotifications()),
        setPaginationRoute: (page) => dispatch(setPaginationRoute(page)),
        clearPaginationRoute: () => dispatch(clearPaginationRoute()),
        clearQuery: () => dispatch(clearQuery()),
        getUsers: () => dispatch(getUsers()),
        setSelectedUser: (selectedUser) => dispatch(setSelectedUser(selectedUser)),

    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer);
