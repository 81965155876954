import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import Select from 'react-select';
import List from '../../application/components/list.component';
import NotificationListCard from './notificationListCard.component';

function NotificationsComponent(props) {

  const { isLoading, notifications, getNotifications, clearNotifications, total, limit, paginationRoute, setPaginationRoute, clearPaginationRoute, clearQuery, sortOptions, getUsers, users, selectedUser, setSelectedUser } = props;

  let { page } = useParams();

  useEffect(() => {
    getNotifications();
    return () => {
      clearPaginationRoute();
      clearNotifications();
      clearQuery();
    }
  }, [getNotifications, clearNotifications, clearPaginationRoute, clearQuery]);

  useEffect(() => {
    getUsers();
    if (page) {
      setPaginationRoute(page || 1);
    }
  }, []);

  return <div className="d-flex flex-fill flex-column ">
    <List
      data={notifications}
      Card={NotificationListCard}
      isLoading={isLoading}
      total={total}
      paginationRoute={parseInt(paginationRoute)}
      limit={limit}
      setPaginationRoute={setPaginationRoute}
      route="/notifications"
      sortOptions={sortOptions}
      users={users}
      selectedUser={selectedUser}
      setSelectedUser={setSelectedUser}
    />
  </div >
}

export default NotificationsComponent;