import Api from '../../application/services/api.service';
import moment from 'moment';

const namespace = 'DIARIES';

export const SET_DIARIES = `SET_DIARIES_${namespace}`;
export const SET_USERS = `SET_USERS_${namespace}`;
export const IS_LOADING = `IS_LOADING_${namespace}`;
export const SET_SELECTED_USER = `SET_SELECTED_USER_${namespace}`;
export const SET_SELECTED_DAY = `SET_SELECTED_DAY_${namespace}`;
export const CLEAR_STORE = `CLEAR_STORE_${namespace}`;

export const setDiaries = (diaries) => ({
  type: SET_DIARIES,
  diaries
});

export const setUsers = (users) => ({
  type: SET_USERS,
  users
});

export function setSelectedUser(selectedUser) {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_USER,
      selectedUser
    });
    dispatch(getDiaries());
  }
}

export function setSelectedDay(selectedDay) {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_DAY,
      selectedDay
    });
    dispatch(getDiaries());
  }
}

export const isLoading = (isLoading) => ({
  type: IS_LOADING,
  isLoading
});

export const clearStore = () => ({
  type: CLEAR_STORE
});

export function getUsers(selectedUser) {
  return dispatch => {

    dispatch(isLoading(true));

    let params = {
      "sort[active]": -1,
      "sort[createdAt]": -1,
      "select": "_id,name"
    };

    return Api.get('admin/users', params).then((result) => {

      dispatch(isLoading(false));

      if (result.success) {
        let preparedSelectedUser = null;
        
        dispatch(setUsers(result.documents.map((user) => {
          if (selectedUser && user._id === selectedUser) {
            preparedSelectedUser = { name: user.name, label: user.name, value: user._id };
          }
          return { name: user.name, label: user.name, value: user._id };
        })));
        
        if (selectedUser && preparedSelectedUser) {
          dispatch(setSelectedUser(preparedSelectedUser));
        }

      } else if ('errors' in result) {
        // dispatch(showToast('alert', 'Błąd podczas pobierania użytkowników'));
      }
    });

  };
}

export function getDiaries() {
  return (dispatch, getState) => {

    const { DiaryReducer: { selectedUser, selectedDay } } = getState();

    // pobieramy tylko wtedy jak wybrany jest użytkownik i data
    if (selectedUser && selectedUser.value && selectedDay) {

      let startOfDay = moment(selectedDay).startOf('day').unix();
      let endOfDay = moment(selectedDay).endOf('day').unix();

      let params = {
        "sort[date]": -1,
        "date[gte]": startOfDay,
        "date[lte]": endOfDay,
        "creator[in]": selectedUser.value
      };

      dispatch(isLoading(true));

      return Api.get('admin/diaries', params).then((result) => {

        // zawsze ukrywamy globalny is loading
        dispatch(isLoading(false));

        if (result.success) {
          dispatch(setDiaries(result.documents));
        } else if ('errors' in result) {
          // dispatch(showToast('alert', 'Błąd podczas pobierania użytkowników'));
        }
      });
    }
  };
}