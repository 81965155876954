import Api from '../../application/services/api.service';
import moment from 'moment';
import { showToast } from '../../application/actions/app.action';
import _ from 'lodash';

const namespace = 'NOTIFICATIONS';

export const SET_NOTIFICATIONS = `SET_NOTIFICATIONS_${namespace}`;
export const CLEAR_NOTIFICATIONS = `CLEAR_NOTIFICATIONS_${namespace}`;
export const SET_NOTIFICATION = `SET_NOTIFICATION_${namespace}`;
export const CLEAR_NOTIFICATION = `CLEAR_NOTIFICATION_${namespace}`;
export const SET_USERS = `SET_USERS_${namespace}`;
export const CLEAR_USERS = `CLEAR_USERS_${namespace}`;
export const IS_LOADING = `IS_LOADING_${namespace}`;
export const SET_PAGE = `SET_PAGE_${namespace}`;
export const CLEAR_PAGE = `CLEAR_PAGE_${namespace}`;
export const SET_OFFSET = `SET_OFFSET_${namespace}`;
export const SET_SELECTED_USER = `SET_SELECTED_USER_${namespace}`;

export const setNotification = (notification) => ({
    type: SET_NOTIFICATION,
    notification
});

export const clearNotification = () => ({
    type: CLEAR_NOTIFICATION
});

export const setNotifications = (notifications, total) => ({
    type: SET_NOTIFICATIONS,
    notifications,
    total
});

export const clearNotifications = () => ({
    type: CLEAR_NOTIFICATIONS
});

export const setUsers = (users) => ({
    type: SET_USERS,
    users
});

export const clearUsers = () => ({
    type: CLEAR_USERS
});


export const isLoading = (isLoading) => ({
    type: IS_LOADING,
    isLoading
});

export function setOffset(offset) {
    return (dispatch) => {
        dispatch({
            type: SET_OFFSET,
            offset: offset
        });
    }
}

export function setPaginationRoute(page) {
    return (dispatch, getState) => {
        const { NotificationReducer: { limit } } = getState();

        dispatch(setOffset(limit * ((page || 1) - 1)));
        dispatch({
            type: SET_PAGE,
            page: page || 1
        });

        dispatch(getNotifications());
        window.scrollTo(0, 0);
    }
}

export function clearPaginationRoute() {
    return dispatch => {
        dispatch(setOffset(0));
        dispatch({
            type: CLEAR_PAGE
        });
        window.scrollTo(0, 0);
    }
}

export function getNotification(notificationId) {
    return dispatch => {

        dispatch(isLoading(true));

        return Api.get(`admin/notifications/${notificationId}`, null).then((result) => {
            dispatch(isLoading(false));
            if (result.success) {
                dispatch(setNotification(result.document));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania powiadomienia'));
            }
        });
    };
}


export function getUsers() {
    return dispatch => {

        dispatch(isLoading(true));

        let params = {
            "sort[active]": -1,
            "sort[createdAt]": -1,
            "active": true,
            "select": "_id,name,birthDate,email,createdAt,active"
        };

        return Api.get('admin/users', params).then((result) => {

            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                dispatch(setUsers(result.documents.map((user) => {
                    return { name: user.name, label: `${user.name}, (urodzony: ${moment(user.birthDate).format("YYYY-MM-DD")}), (data rejestracji: ${moment(user.createdAt).format("YYYY-MM-DD")})`, value: user._id };
                })));

            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania użytkowników'));
            }
        });

    };
}


export function setSelectedUser(selectedUser) {
    return dispatch => {
        dispatch({
            type: SET_SELECTED_USER,
            selectedUser
        });
        dispatch(getNotifications());
    }
}

const getUserList = () => dispatch => {
    return new Promise((resolve, reject) => {

        let params = {
            "sort[active]": -1,
            "sort[createdAt]": -1,
            "active": true,
            "select": "_id,name"
        };

        return Api.get('admin/users', params).then((result) => {
            if (result.success) {
                dispatch(
                    setUsers(result.documents.map(user => {
                        return {
                            label: user.name,
                            name: user.name,
                            value: user._id
                        }
                    }))
                );
                resolve(result.documents);
            }
        });
    })
}

export function getNotifications() {
    return (dispatch, getState) => {

        const { NotificationReducer: { limit, offset, queryFields, sortOptions, selectedUser }, AppReducer: { searchQuery, sortBy } } = getState();

        let defaultParams = {
            "sort[active]": -1,
            "limit": limit,
            "offset": offset
        }

        let defaultSortOption = sortOptions.filter((option) => {
            return option.default;
        })[0];

        let params = Object.assign({}, defaultParams, sortBy ? sortBy.value : defaultSortOption.value);

        if (searchQuery && queryFields) {
            queryFields.split(",").forEach((field) => {
                params[`${field}[orin]`] = searchQuery;
            });
        }

        if (selectedUser) {
            params[`users[in]`] = selectedUser.value
        }

        dispatch(isLoading(true));

        return Api.get('admin/notifications', params).then(async (result) => {
            // zawsze ukrywamy globalny is loading
            dispatch(isLoading(false));

            if (result.success) {
                const users = await dispatch(getUserList());

                result.documents.map(doc => {
                    doc.usernames = []
                    doc.users.forEach(user => {
                        doc.usernames.push(_.find(users, (o) => { return o._id === user }))
                    })
                })
                dispatch(setNotifications(result.documents, result.total));
            } else if ('errors' in result) {
                dispatch(showToast('alert', 'Błąd podczas pobierania powiadomień'));
            }
        });

    };
}

export function saveNotification(notificationData, dispatch) {
    return new Promise((resolve, reject) => {

        dispatch(isLoading(true));

        if (notificationData._id) { // edycja
            return Api.put('admin/notifications', notificationData, notificationData._id).then((result) => {
                dispatch(isLoading(false));
                if (result.success) {
                    return resolve();
                } else if ('errors' in result) {
                    dispatch(showToast('alert', 'Powiadomienie zostało zaktualizowane'));
                    return reject(result.errors);
                }
            });
        } else { // dodawanie
            return Api.post('admin/notifications', notificationData).then((result) => {
                dispatch(isLoading(false));
                if (result.success) {
                    dispatch(showToast('info', 'Powiadomienie zostało dodane'));
                    return resolve();
                } else if ('errors' in result) {
                    dispatch(showToast('alert', 'Błąd podczas dodawania powiadomienia'));
                    return reject(result.errors);
                }
            });
        }
    });
}

export function deleteNotification(id, dispatch) {
    return new Promise((resolve, reject) => {

        dispatch(isLoading(true));
        return Api.delete(`admin/notifications/${id}`).then((result) => {
            dispatch(isLoading(false));
            if (result.success) {
                dispatch(showToast('info', 'Powiadomienie zostało usunięte'));
                return resolve();
            } else  {
                dispatch(showToast('alert', 'Błąd podczas usuwania powiadomienia'));
                return reject();
            }
        });
    });
}