import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import NotificationComponent from '../components/notification.component';

import { getNotification, clearNotification, getUsers, clearUsers, saveNotification, deleteNotification } from '../actions/notifications.action';

function NotificationContainer(props) {

    // pobieranie użytkowników do selekta
    const { getUsers, clearUsers } = props;

    useEffect(() => {
        getUsers();
        return () => {
            clearUsers();
        }
    }, [getUsers, clearUsers]);

    return (
        <NotificationComponent {...props} />
    );
}

const mapStateToProps = (state) => {
    return {
        isLoading: state.NotificationReducer.isLoading,
        users: state.NotificationReducer.users,
        notification: state.NotificationReducer.notification
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getNotification: (id) => dispatch(getNotification(id)),
        clearNotification: () => dispatch(clearNotification()),
        saveNotification: (data) => saveNotification(data, dispatch),
        deleteNotification: (id) => deleteNotification(id, dispatch),
        getUsers: () => dispatch(getUsers()),
        clearUsers: () => dispatch(clearUsers())
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationContainer);
