import { combineReducers } from 'redux';

import AppReducer from './app.reducer';
import UserReducer from '../../users/reducers/user.reducer';
import NotificationReducer from '../../notifications/reducers/notification.reducer';
import DiaryReducer from '../../diaries/reducers/diary.reducer';

//LISTA REDUCERÓW
export default combineReducers({
  AppReducer,
  UserReducer,
  NotificationReducer,
  DiaryReducer
});